import React, { useEffect, useState } from 'react';
import '../styles/CpExposFormStepThree.css'

const StructureSection = ({ structureInfo, setStructureInfo, odooStreetTypes, errors }) => {

  const [structureInfoDisabledInputs, setStructureInfoDisabledInputs] = useState([])

  useEffect(() => {

    // Récupérer les clés non nulles de structureInfo
    const nonNullKeys = Object.keys(structureInfo).filter(
      (key) => structureInfo[key] !== null && structureInfo[key] !== undefined
    );

    // Mettre à jour le state avec les clés non nulles
    setStructureInfoDisabledInputs(nonNullKeys);

  }, []);

  /*
        <label>Code APE</label>
        <input
          type="text"
          value={structureInfo.structureApeId || ''}
          onChange={(e) => setStructureInfo({ ...structureInfo, structureApeId: e.target.value ? e.target.value : null })}
        />
 */

  return (
    <div className='step-three-form-open-section'>
      <div className='step-three-form-section'>
        <h5>Structure</h5>

        <label style={{ color: errors['siret'] ? 'red' : '' }}>Siret *</label>
        <input
          type="text"
          value={structureInfo.siret || ''}
          disabled={structureInfoDisabledInputs.includes('siret')}
          onChange={(e) => setStructureInfo({ ...structureInfo, siret: e.target.value ? e.target.value : null })}
        />

        <label style={{ color: errors['name'] ? 'red' : '' }}>Raison Sociale *</label>
        <input
          type="text"
          value={structureInfo.name || ''}
          disabled={structureInfoDisabledInputs.includes('name')}
          onChange={(e) => setStructureInfo({ ...structureInfo, name: e.target.value ? e.target.value : null })}
        />

        <label>Code APE</label>
        <input
          type="text"
          value={structureInfo.structureApeId || ''}
          disabled={structureInfoDisabledInputs.includes('structureApeId')}
          onChange={(e) => setStructureInfo({ ...structureInfo, structureApeId: e.target.value ? e.target.value : null })}
        />

        <label style={{ color: errors['commercialName'] ? 'red' : '' }}>Sigle ou nom commercial (nom que vous souhaitez voir apparaître) * :</label>
        <input
          type="text"
          value={structureInfo.commercialName || ''}
          disabled={structureInfoDisabledInputs.includes('commercialName')}
          onChange={(e) => setStructureInfo({ ...structureInfo, commercialName: e.target.value ? e.target.value : null })}
        />

        <label style={{ color: errors['email'] ? 'red' : '' }}>Adresse email *</label>
        <input
          type="email"
          value={structureInfo.email || ''}
          disabled={structureInfoDisabledInputs.includes('email')}
          onChange={(e) => setStructureInfo({ ...structureInfo, email: e.target.value ? e.target.value : null })}
        />

        <div className='structure-section-address-infos'>
          <div>
            <label>N° de la voie</label>
            <input
              type="text"
              value={structureInfo.customAddressStreetNumber || ''}
              disabled={structureInfoDisabledInputs.includes('customAddressStreetNumber')}
              onChange={(e) => setStructureInfo({ ...structureInfo, customAddressStreetNumber: e.target.value ? e.target.value.toString() : null })}
            />
          </div>
          <div>
            <label>Nature de la voie</label>
            <input
              type="text"
              value={structureInfo.customAddressStreetNumberIndex || ''}
              disabled={structureInfoDisabledInputs.includes('customAddressStreetNumberIndex')}
              onChange={(e) => setStructureInfo({ ...structureInfo, customAddressStreetNumberIndex: e.target.value ? e.target.value : null })}
            />
          </div>
          <div>
            <label>Libellé de la voie</label>
            <input
              type="text"
              value={structureInfo.customAddressStreetLabel || ''}
              disabled={structureInfoDisabledInputs.includes('customAddressStreetLabel')}
              onChange={(e) => setStructureInfo({ ...structureInfo, customAddressStreetLabel: e.target.value ? e.target.value : null })}
            />
          </div>
          <div>
            <label>Complément</label>
            <input
              type="text"
              value={structureInfo.customAddressStreetDetails || ''}
              disabled={structureInfoDisabledInputs.includes('customAddressStreetDetails')}
              onChange={(e) => setStructureInfo({ ...structureInfo, customAddressStreetDetails: e.target.value ? e.target.value : null })}
            />
          </div>
        </div>

        <label>Lieu dit, boîte postale</label>
        <input
          type="text"
          value={structureInfo.customAddressStreetDetailsInfos || ''}
          disabled={structureInfoDisabledInputs.includes('customAddressStreetDetailsInfos')}
          onChange={(e) => setStructureInfo({ ...structureInfo, customAddressStreetDetailsInfos: e.target.value ? e.target.value : null })}
        />

        <label>Code Postal</label>
        <input
          type="text"
          value={structureInfo.zip || ''}
          disabled={structureInfoDisabledInputs.includes('zip')}
          onChange={(e) => setStructureInfo({ ...structureInfo, zip: e.target.value ? e.target.value : null })}
        />

        <label>Ville</label>
        <input
          type="text"
          value={structureInfo.city || ''}
          disabled={structureInfoDisabledInputs.includes('city')}
          onChange={(e) => setStructureInfo({ ...structureInfo, city: e.target.value ? e.target.value : null })}
        />
      </div>
    </div>
  );
};

export default StructureSection;
