import React from 'react';

const OdooDeliveryAddress = ({ odooDeliveryAdress, onSelect, isSelected }) => {
  return (
    <div 
      className={`step-three-form-section ${isSelected ? 'selected-address' : ''}`} 
      onClick={onSelect} 
      style={{ 
        marginRight: '1em',
        cursor: 'pointer', 
        padding: '10px', 
        border: isSelected ? '2px solid #4CAF50' : '1px solid #ddd', 
        borderRadius: '5px', 
        backgroundColor: isSelected ? '#f9fff9' : '#fff' 
      }}
    >
      <h4 style={{ textAlign: 'left' }}>{odooDeliveryAdress.name}</h4>
      <p style={{ textAlign: 'left' }}>{odooDeliveryAdress.contactAddressComplete  || '-'}</p>
    </div>
  );
};

export default OdooDeliveryAddress;
