import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { CircularProgress, Fade } from "@mui/material";
import { Form, Container, Button } from 'react-bootstrap';
import cap_metiers_logo from '../../assets/logo-cap-metiers.png'
import '../../styles/pages.css'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';


function Memori() {

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatDateOutput = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont indexés de 0 à 11
        const day = dateObject.getDate().toString().padStart(2, '0');
        return `${day}/${month}/${year}`;
    };

    const [type, setType] = useState("");
    const [gender, setSexe] = useState();
    const [token, setToken] = useState();
    const [questions, setQuestions] = useState();
    const [age, setAge] = useState();
    const [contactAuthorization, setContactAuthorization] = useState();
    const [formData, setFormData] = useState({
        askDate: getCurrentDate(),
        knowingType: "",
        askOrigin: "",
        gender: "",
        structureType: "",
        structurePhone: "",
        structureMail: "",
        structureDepartment: "",
        age: "",
        lastDiploma: "",
        status: "",
        askNature: "",
        askDescription: "",
        cityName: "",
        cityZipCode: "",
        phone: "",
        mail: "",
        cityINSEE: "",
        contactAuthorization: "",
    });
    const [cityList, setCityList] = useState([]);
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState();
    const [sendingSuccess, setSendingSucces] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChangePhoneNumber = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    /* 
        Retourne vrai si le formulaire est valide, faux sinon.
        Donne également un détail de l'erreur dans la variable d'état errors
    */
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        const required = "Le champ est obligatoire";

        if (!formData["gender"]) {
            newErrors["gender"] = required;
            isValid = false;
        }
        if (!formData["cityName"]) {
            newErrors["cityName"] = required;
            isValid = false;
        }
        if (!formData["age"]) {
            newErrors["age"] = required;
            isValid = false;
        }
        if (!formData['mail']) {
            newErrors["mail"] = required;
            isValid = false;
        }
        if (!formData['phone']) {
            newErrors["phone"] = required;
            isValid = false;
        }
        if (!formData['askDescription']) {
            newErrors["askDescription"] = required;
            isValid = false;
        }
        if (!formData['lastDiploma']) {
            newErrors["lastDiploma"] = required;
            isValid = false;
        }
        if (!formData['askNature']) {
            newErrors["askNature"] = required;
            isValid = false;
        }
        if (!formData['knowingType']) {
            newErrors["knowingType"] = required;
            isValid = false;
        }
        if (!formData['status']) {
            newErrors["status"] = required;
            isValid = false;
        }
        if (!formData['contactAuthorization']) {
            newErrors["contactAuthorization"] = required;
            isValid = false;
        }
        if (!formData['askOrigin']) {
            newErrors["askOrigin"] = required;
            isValid = false;
        }
        /* Si type existe et est de type "Professionnel" alors on fait la vérification */
        if (type && type === "Professionnel" && !formData['structureType']) {
            newErrors["structureType"] = required;
            isValid = false;
        }
        /* Si type existe et est de type "Professionnel" alors on fait la vérification */
        if (type && type === "Professionnel" && !formData['structureDepartment']) {
            newErrors["structureDepartment"] = required;
            isValid = false;
        }

        setErrors(newErrors);

        return isValid;
    };


    const fetchToken = async () => {
        setLoading(true);
        const requestData = {
            email: process.env.REACT_APP_MEMORI_ACCOUNT_EMAIL,
            password: process.env.REACT_APP_MEMORI_ACCOUNT_PASSWORD,
        };
        const requestBody = JSON.stringify(requestData);
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axios.post(`${process.env.REACT_APP_MEMORI_API_URL}/login`, requestBody, { headers });
        setToken(response.data.token);

    }

    useEffect(() => {
        fetchToken();
    }, []);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            validateForm();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    useEffect(() => {

        const headers = {
            'Authorization': `Bearer ${token}`,
        }

        if (token) {
            axios.get(`${process.env.REACT_APP_MEMORI_API_URL}/v1/enum_form?pagination=false`, { headers }).then((response) => {
                const data = response.data['hydra:member'];

                const cachedDataObj = {
                    date: new Date().toISOString(),
                    data: data,
                };

                setLoading(false);
                setQuestions(cachedDataObj);
            });
        }

    }, [token]);

    const handleChangeCity = async (inputValue) => {
            const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${inputValue}&type=municipality&autocomplete=1`);
            if (response.data.features) {
                setCityList(response.data.features);
            }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendData = async (body) => {

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }

        try {
            await axios.post(`${process.env.REACT_APP_MEMORI_API_URL}/v1/import_form`,
                body,
                { headers }
            )
            setSendingSucces(true);

        } catch (e) {
            setServerError('Problème interne, veuillez réessayer plus tard.')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm();

        if (isValid) {

            let cleanedFormData = formData;

            // on met les dates au format JJ/MM/YYYY
            cleanedFormData = { ...cleanedFormData, askDate: formatDateOutput(cleanedFormData.askDate) };
            if (type && type === "Particulier") {
                cleanedFormData = {
                    ...cleanedFormData,
                    structureType: null,
                    structureDepartment: null,
                    structurePhone: null,
                    structureMail: null
                }
            }

            const formDataJSON = JSON.stringify(cleanedFormData);

            sendData(formDataJSON);

        }

    };

    return (
        (sendingSuccess && (
            <Container style={{ padding: '1em', maxWidth: '50%', alignItems: 'center', marginTop: '7em' }}>
                <img src={cap_metiers_logo} alt='cap_metiers_logo' />
                <div style={{ marginTop: '1em', fontSize: 'x-large' }}>
                    <p>✅ Votre demande d'information est enregistrée.</p>
                    <p>Les conseillers de Cap Métiers vous répondront dans les plus brefs délais.</p>
                </div>
            </Container>
        ))
        ||
        (loading && (
            <div className="d-flex justify-content-center" style={{ marginTop: '7em' }}>
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: loading ? '800ms' : '0ms',
                    }}
                    unmountOnExit
                >
                    <CircularProgress color="secondary" />
                </Fade>
            </div>
        )
        )
        ||
        (
            <Container>
                {
                    questions &&
                    <Form onSubmit={handleSubmit} className='memori-form-container'>
                        <h3 className='memori-section-title'>Contact</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className='memori-form-field'>
                                    <Form.Label>{questions.data.filter(item => item.code === "8").map(q => q.label)}</Form.Label>
                                    <Form.Control
                                        type="date" 
                                        name="askDate"
                                         value={formData.askDate} 
                                         placeholder={formData.askDate}  
                                         readOnly
                                         style={{ backgroundColor: '#e9ecef', cursor: 'not-allowed', color: 'grey'}}
                                         />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className='memori-form-field'>
                                    <Form.Label style={{ color: errors['knowingType'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "15").map(q => q.label)} *</Form.Label>
                                    <Form.Control
                                        as="select" key='jsfdksd' name="knowingType" value={formData.knowingType} onChange={handleInputChange}>
                                        <option id={"jfkjdkf"} value="">Sélectionnez une réponse</option>
                                        {
                                            questions.data
                                                .filter(item => item.code === "15")
                                                .map(q => (
                                                    q.options.map(option => (
                                                        <option key={option['@id']} value={option['@id']}>{option.label}</option>
                                                    ))
                                                ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        </div>


                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['askOrigin'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "17").map(q => q.label)} *</Form.Label>
                            {
                                questions.data
                                    .filter(item => item.code === "17")
                                    .map(q => (
                                        q.options.map(option => (
                                            <Form.Check
                                                id={`checkbox-${option['@id']}`}
                                                type="checkbox"
                                                name='askOrigin'
                                                label={option.label}
                                                onChange={(e) => {
                                                    setType(option.label);
                                                    handleInputChange(e);
                                                }}
                                                value={option['@id']}
                                                checked={type === option.label && type !== undefined}
                                            />
                                        ))
                                    ))
                            }
                        </Form.Group>

                        {
                            type && type === "Professionnel" && (
                                <div>
                                    <Form.Group className='memori-form-field'>
                                        <Form.Label style={{ color: errors['structureType'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "18").map(q => q.label)} *</Form.Label>
                                        <Form.Control
                                            as="select" key='dkjfkdk' name="structureType" value={formData.structureType} onChange={handleInputChange}>
                                            <option id={"jfkjdkf"} value="">Sélectionnez une réponse</option>
                                            {
                                                questions.data
                                                    .filter(item => item.code === "18")
                                                    .map(q => (
                                                        q.options.map(option => (
                                                            <option key={option['@id']} value={option['@id']}>{option.label}</option>
                                                        ))
                                                    ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group className='memori-form-field'>
                                        <Form.Label>{questions.data.filter(item => item.code === "20c").map(q => q.label)}</Form.Label>
                                        <input
                                            className='form-control'
                                            type="tel"
                                            maxLength={14}
                                            pattern="^0[0-9]([\-\/\.]?\s?[0-9]{2}){4}$"
                                            name="structurePhone"
                                            value={formData.structurePhone}
                                            id="phone"
                                            title="0123456789"
                                            onChange={handleChangePhoneNumber} />
                                    </Form.Group>
                                    <Form.Group className='memori-form-field'>
                                        <Form.Label>{questions.data.filter(item => item.code === "20d").map(q => q.label)}</Form.Label>
                                        <Form.Control
                                            type="email" name="structureMail" value={formData.structureMail} onChange={handleInputChange} />
                                    </Form.Group>
                                    <Form.Group className='memori-form-field'>
                                        <Form.Label style={{ color: errors['structureDepartment'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "19").map(q => q.label)} *</Form.Label>
                                        <Form.Control
                                            as="select" name="structureDepartment" value={formData.structureDepartment} onChange={handleInputChange}>
                                            <option id={"jfkjdkf"} value="">Sélectionnez une réponse</option>
                                            {
                                                questions.data
                                                    .filter(item => item.code === "19")
                                                    .map(q => (
                                                        q.options.map(option => (
                                                            <option key={option['@id']} value={option['@id']}>{option.label}</option>
                                                        ))
                                                    ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                            )
                        }

                        <h3 className='memori-section-title'>Personne concernée par la demande</h3>

                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['gender'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "21").map(q => q.label)} *</Form.Label>
                            <div className="row">
                                {
                                    questions.data
                                        .filter(item => item.code === "21")
                                        .map(q => (
                                            q.options.map(option => (
                                                <div key={'key'+option['@id']} className="col-4">
                                                    <Form.Check
                                                id={`checkbox-${option['@id']}`}
                                                type="checkbox"
                                                        name='gender'
                                                        label={option.label}
                                                        onChange={(e) => {
                                                            setSexe(option.label);
                                                            handleInputChange(e);
                                                        }}
                                                        value={option['@id']}
                                                        checked={gender === option.label && gender !== undefined}
                                                    />
                                                </div>
                                            ))
                                        ))
                                }
                            </div>
                        </Form.Group>


                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['age'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "22").map(q => q.label)} *</Form.Label>
                            <div className="row">
                                {
                                    questions.data
                                        .filter(item => item.code === "22")
                                        .map(q => (
                                            q.options.map(option => (
                                                <div key={'key'+option['@id']} className="col-4">
                                                    <Form.Check
                                                id={`checkbox-${option['@id']}`}

                                                        type="checkbox"
                                                        name='age'
                                                        label={option.label}
                                                        onChange={(e) => {
                                                            setAge(option.label);
                                                            handleInputChange(e);
                                                        }}
                                                        value={option['@id']}
                                                        checked={age === option.label && age !== undefined}
                                                    />
                                                </div>
                                            ))
                                        ))
                                }
                            </div>
                        </Form.Group>


                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['lastDiploma'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "25").map(q => q.label)} *</Form.Label>
                            <Form.Control
                                as="select" name="lastDiploma" value={formData.lastDiploma} onChange={handleInputChange}>
                                <option id={"jfkjdggfhkf"} value="">Sélectionnez un niveau de formation</option>
                                {
                                    questions.data
                                        .filter(item => item.code === "25")
                                        .map(q => (
                                            q.options.map(option => (
                                                <option key={option['@id']} value={option['@id']}>{option.label}</option>
                                            ))
                                        ))
                                }
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['status'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "27").map(q => q.label)} *</Form.Label>
                            <Form.Control
                                as="select" name="status" value={formData.status} onChange={handleInputChange}>
                                <option key={"hghjg"} value="">Sélectionnez un statut</option>
                                {
                                    questions.data
                                        .filter(item => item.code === "27")
                                        .map(q => (
                                            q.options.map(option => (
                                                <option id={option['@id']} value={option['@id']}>{option.label}</option>
                                            ))
                                        ))
                                }
                            </Form.Control>
                        </Form.Group>

                        <h3 className='memori-section-title'>Demande</h3>

                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['askNature'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "35").map(q => q.label)} *</Form.Label>
                            <Form.Control
                                as="select" key='ldkfkkdl' name="askNature" value={formData.askNature} onChange={handleInputChange}>
                                <option id={"dsfdfvgf"} value="">Sélectionnez la nature de la demande</option>
                                {
                                    questions.data
                                        .filter(item => item.code === "35")
                                        .map(q => (
                                            q.options.map(option => (
                                                <option id={option.id} value={option['@id']}>{option.label}</option>
                                            ))
                                        ))
                                }
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['askDescription'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "37").map(q => q.label)} *</Form.Label>
                            <Form.Control
                                as="textarea" name="askDescription" value={formData.askDescription} onChange={handleInputChange} />
                        </Form.Group>

                        <h3 className='memori-section-title'>Coordonnées de la personne concernée par la demande</h3>

                        <Form.Group className='memori-form-field'>
                            <Form.Label  style={{ color: errors['cityName'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "23").map(q => q.label)} *</Form.Label>
                                <AsyncTypeahead
                                    filterBy={() => true}
                                    className='memori'
                                    labelKey={(cityList) => `${cityList.properties.name} (${cityList.properties.postcode})`}
                                    minLength={3}
                                    onSearch={handleChangeCity}
                                    options={cityList}
                                    emptyLabel=""

                                    promptText="Tapez pour chercher..."
                                    placeholder="Rechercher une commune"
                                    onChange={(selected) => {
                                        if (selected && selected.length > 0) {
                                            const newValue = selected[0];
                                            setFormData({
                                                ...formData,
                                                cityName: newValue.properties.city,
                                                cityZipCode: newValue.properties.postcode,
                                                cityINSEE: newValue.properties.citycode,
                                            });
                                        } else {
                                            setCityList([]);
                                        }
                                    }}
                                    inputProps={{ style: { height: '3em' } }}
                                />


                                
                        </Form.Group>
                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['phone'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "20c").map(q => q.label)} *</Form.Label>
                                <input
                                    maxLength={14}
                                    className='form-control'
                                    type="tel"
                                    pattern="^0[0-9]([\-\/\.]?\s?[0-9]{2}){4}$"
                                    id="phone"
                                    name="phone"
                                    title="0123456789"
                                    value={formData.phone}
                                    onChange={handleChangePhoneNumber}
                                />

                        </Form.Group>

                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['mail'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "20d").map(q => q.label)} *</Form.Label>
                            <Form.Control
                                type="email" name="mail" value={formData.mail} onChange={handleInputChange} />
                        </Form.Group>

                        <Form.Group className='memori-form-field'>
                            <Form.Label style={{ color: errors['contactAuthorization'] ? 'red' : '' }}>{questions.data.filter(item => item.code === "71c").map(q => q.label)} *</Form.Label>
                            {
                                questions.data
                                    .filter(item => item.code === "71c")
                                    .map(q => (
                                        q.options.map(option => (
                                            <Form.Check
                                            id={`checkbox-${option['@id']}`}

                                                type="checkbox"
                                                name='contactAuthorization'
                                                label={option.label}
                                                onChange={(e) => {
                                                    setContactAuthorization(contactAuthorization === option.label ? undefined : option.label);
                                                    handleInputChange(e);
                                                }}
                                                value={option['@id']}
                                                checked={contactAuthorization === option.label && contactAuthorization !== undefined}
                                            />
                                        ))
                                    ))
                            }
                        </Form.Group>

                        <div className="error-message">
                            {Object.keys(errors).length > 0 && (
                                <p style={{ color: 'red', marginBottom: '0', marginTop: '1em' }}>
                                    Veuillez remplir tous les champs obligatoires *
                                </p>
                            )}
                        </div>
                        {
                            serverError && <p style={{ color: 'red', marginBottom: '0', marginTop: '1em' }}>
                                {serverError}
                            </p>
                        }
                        {
                            sendingSuccess && <p style={{ color: 'green', marginBottom: '0', marginTop: '1em' }}>
                                {sendingSuccess}
                            </p>
                        }
                        <Button type="submit" className="btn primary mt-3" >Envoyer</Button>
                    </Form>
                }
            </Container>
        )

    );
}

export default Memori;