import React, {useEffect, useState} from "react";
import {ListGroup} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {
    Grid, styled, TextField
} from "@mui/material";
import axios from "axios";
import "../../styles/pages.css";
import Typography from "@mui/material/Typography";
import {wait} from "@testing-library/user-event/dist/utils";
import { PatternFormat } from 'react-number-format';
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";

export default function Referencement(props) {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = React.useState(false);
    const [inpuValue, setInputValue] = React.useState("");
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState({});
    const [isSiretExistOdoo, setIsSiretExistOdoo] = useState(null);
    const [isSiretValid, setIsSiretValid] = useState(false);
    const [messageSiret, setMessageSiret] = useState('');
    const [stepOK, setStepOK] = useState(false);
    const [selected, setSelected] = useState(false);

    const params = useParams();
    
    useEffect(() => {
        if (params.siret) {
            setInputValue(params.siret);
            fetch(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&q=siret:${params.siret}&refine.etatadministratifetablissement=Actif`)
                .then(response => response.json())
                .then(responseData => {
                    setOptions(responseData.records);
                    wait(500);
                    if (params.siret.length === 14 && responseData.records.length === 0) {
                        setIsSiretValid(EstSiretValide(params.siret));
                        if (EstSiretValide(params.siret)) {
                            wait(500);
                            setStepOK(true);
                            props.onStepOk(true);
                            setMessageSiret("Aucun résultat sur la base SIREN, veuillez cliquer sur le bouton « suivant » pour saisir vos informations.  ")
                        }else{
                            setStepOK(false);
                            props.onStepOk(false);
                            setMessageSiret("Aucun résultat : le SIRET est incorrect, veuillez corriger la saisie pour passer à l’étape suivante.");
                        }
                    }
                    else {
                        //setSearch(responseData.records[0].fields);
                        //checkSiret(params.siret);
                    }
                })
                .catch(error => {
                    console.log('Error fetching and parsing data', error);
                });
        }
    }, [params.siret, props]);


    const handleChange = event => {
        setIsSiretExistOdoo(null);
        setStepOK(false);
        setSelected(false);
        setOptions([]);
        setSearch({});
        setMessageSiret("");
        props.onStepOk(false);
        setInputValue(event.target.value);
        fetch(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&q=siren:${event.target.value}%20OR%20siret:${event.target.value}&refine.etatadministratifetablissement=Actif`)
            .then(response => response.json())
            .then(responseData => {
                setOptions(responseData.records);
                wait(500);
                if (event.target.value.length === 14 && responseData.records.length === 0) {
                    setIsSiretValid(EstSiretValide(event.target.value));
                    if (EstSiretValide(event.target.value)) {
                        wait(500);
                        setStepOK(true);
                        props.onStepOk(true);
                        setMessageSiret("Aucun résultat sur la base SIREN, veuillez cliquer sur le bouton « suivant » pour saisir vos informations.  ")
                    }else{
                        setStepOK(false);
                        props.onStepOk(false);
                        setMessageSiret("Aucun résultat : le SIRET est incorrect, veuillez corriger la saisie pour passer à l’étape suivante.");
                    }
                }
            })
            .catch(error => {
                console.log('Error fetching and parsing data', error);
            });
    };

    const handleSelect = (array) => {
        setIsSiretExistOdoo(null);
        setSelected(true);
        setOptions([]);
        setMessageSiret("");
        setSearch(array);
        props.callback(search);
    }

    const checkSiret = async (siret) => {
        setIsSiretExistOdoo(null);
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/structure/${siret}`);
        if (response.data.status) {
            setLoading(false);
            setIsSiretExistOdoo(true);
            props.onStepOk(false);
        }else{
            setLoading(false);
            setIsSiretExistOdoo(false);
            props.onStepOk(true);
        }
    }

    function EstSiretValide(siret) {
        let estValide;
        if ( (siret.length !== 14) || (isNaN(siret)) )
            estValide = false;
        else {
            let somme = 0;
            let tmp;
            for (let cpt = 0; cpt<siret.length; cpt++) {
                if ((cpt % 2) === 0) { // Les positions impaires : 1er, 3è, 5è, etc...
                    tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
                    if (tmp > 9)
                        tmp -= 9;	// Si le résultat est supérieur à 9, on lui soustrait 9
                }
                else
                    tmp = siret.charAt(cpt);
                somme += parseInt(tmp);
            }
            if ((somme % 10) === 0)
                estValide = true; // Si la somme est un multiple de 10 alors le SIRET est valide
            else
                estValide = false;
        }
        return estValide;
    }

    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.blue,
    }));

    return (
        <div className="">
            <div className="">
                <div className="">
                    <div className="mt-5 justify-content-center">
                            <PatternFormat
                                fullWidth
                                customInput={TextField}
                                size="small"
                                value={params.siret?params.siret:inpuValue}
                                onChange={handleChange}
                                label="Saisissez votre Siren/Siret puis sélectionnez votre structure"
                                variant="outlined"
                                format="##############"
                            />

                    </div>
                    <ul className="mt-3 text-help-siren">
                        <li>Le numéro Siren est un identifiant à 9 chiffres (exemple : 120027016)</li>
                        <li>Le numéro Siret est un identifiant à 14 chiffres (exemple : 12002701600357)</li>
                    </ul>
                    <div className="text-center mt-3">
                        <div><strong>{search.siret}</strong></div>

                            {inpuValue.length === 14 && options.length === 0 &&  (
                                <div className="alert">{messageSiret}</div>
                            )}
                                <div>
                                    <span>{search.denominationunitelegale || search.nomunitelegale  } - {search.libellecommuneetablissement }</span><br />
                                    <span className="d-flex justify-content-center">
                                        <Fade
                                            in={loading}
                                            style={{
                                                transitionDelay: loading ? '800ms' : '0ms',
                                            }}
                                            unmountOnExit
                                        >
                                            <CircularProgress size={30} color="inherit" />
                                        </Fade>
                                    </span>
                                    {search.siret && isSiretExistOdoo === true && <span>Cette structure est déjà référencée dans Cap Métiers. Pour créer votre compte utilisateur et accéder aux services Cap Métiers, remplissez ce&nbsp;
                                     <a href="https://sso.cap-metiers.pro/v2/account-requests/add">formulaire</a> (recherchez votre structure par nom ou SIRET puis complétez le reste du formulaire). Le compte n’est pas validé instantanément. Il est soumis à validation des gestionnaires Cap Métiers.
                                    </span>}

                                    {(search.siret && isSiretExistOdoo === false) && <span>Non Référencée à CAP Métiers</span>}

                                </div>

                    </div>

                    <Grid item xs={12} md={6}>
                        { selected === false  && (
                            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                                {options.length} - Résultat(s)
                            </Typography>
                        )}
                    <Demo>
                        <ListGroup>
                        {options.map((result) =>
                            <ListGroup.Item  value={result.fields} onClick={ () => {handleSelect(result.fields); checkSiret(result.fields.siret); props.callback(result.fields);}}
                                            action as="li" className="siren-item d-flex justify-content-between align-items-start" >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{result.fields.denominationunitelegale || result.fields.nomunitelegale  } - {result.fields.libellecommuneetablissement }</div>
                                    {result.fields.siret}
                                </div>
                            </ListGroup.Item>
                        )}
                        </ListGroup>
                    </Demo>
                    </Grid>
                </div>
            </div>
        </div>
    );
};
