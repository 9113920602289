import { MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

const ContactSection = ({ newContact, setNewContact, errors }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact({ ...newContact, [name]: value });
  };

  const handleFirstNameChange = (e) => {
    const firstName = e.target.value;
    setNewContact({ ...newContact, name: `${firstName} ${newContact.lastName || ''}`.trim(), firstName });
  };

  const handleLastNameChange = (e) => {
    const lastName = e.target.value;
    setNewContact({ ...newContact, name: `${newContact.firstName || ''} ${lastName}`.trim(), lastName });
  };

  return (
    <div className='step-three-form-open-section'>
      <div className='step-three-form-section'>
        <div>
          <div className="contact-section">
            <h5>Contact</h5>

            <label style={{ color: errors['name'] ? 'red' : '' }}>Nom*</label>
            <input name="lastName" type="text" value={newContact.lastName || ''} onChange={handleLastNameChange} />

            <label style={{ color: errors['name'] ? 'red' : '' }}>Prénom*</label>
            <input name="firstName" type="text" value={newContact.firstName || ''} onChange={handleFirstNameChange} />

            <label style={{ color: errors['email'] ? 'red' : '' }}>Email*</label>
            <input name="email" type="text" value={newContact.email} onChange={handleChange} />

            <label style={{ color: errors['mobile'] ? 'red' : '' }}>Téléphone mobile*</label>
            <input
            className='form-control'
            type="tel"
            pattern="^0[0-9]([\-\/\.]?\s?[0-9]{2}){4}$"
            id="phone"
            title="0123456789"
            name="mobile"
            value={newContact.mobile} 
            onChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
