import '../../styles/CpExposFormFinalStep.css'

const CpExposFinalStep = () => {
    return (
        <>
        <div className='expos-final-step-container'>
        <h1>La demande a été soumise</h1>
        <p>Votre demande a bien été prise en compte. Nous vous recontacterons dans les prochains jours. ✅</p>
        </div>
        </>
    )
}

export default CpExposFinalStep