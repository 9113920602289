import React, { useState, useEffect } from 'react';
import '../../styles/CpExposFormStepThree.css';
import StructureSection from '../../components/StructureSection';
import AddressSection from '../../components/AddressSection';
import OdooStructureCard from '../../components/OdooStructureCard';
import OdooDeliveryAddress from '../../components/OdooDeliveryAdressCard';
import ContactSection from '../../components/ContactSection';
import { parseISO } from 'date-fns';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { Form } from 'react-bootstrap';

const CpExposFormStepThree = ({ product, reservedDates, onNext, onPrevious }) => {

  const [siret, setSiret] = useState('');
  const [structureExists, setStructureExists] = useState(false);
  const [adressSectionIsOpen, setAdressSectionIsOpen] = useState(false);
  const [odooStructure, setOdooStructure] = useState(null);
  const [odooStreetTypes, setOdooStreetTypes] = useState(null);
  const [odooApeCatalog, setOdooApeCatalog] = useState(null);
  const [deliveryAddresses, setDeliveryAdresses] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const [newAddress, setNewAddress] = useState(null);

  const [newContact, setNewContact] = useState(null)

  const [newStructure, setNewStructure] = useState(null);

  const [structureErrors, setStructureErrors] = useState({});

  const [addressesErrors, setAddresesErrors] = useState({});

  const [contactErrors, setContactErrors] = useState({});

  const formatToLocalISOString = (date) => {
    const d = new Date(date);
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}T${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
  };

  const fetchOpenDataSoftStructureInfo = async (siret) => {
    try {
      const response = await fetch(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&q=siret:${siret}&refine.etatadministratifetablissement=Actif`);
      const data = await response.json();
      if (data && data.records && data.records.length > 0) {
        const company = data.records[0].fields;

        setNewStructure({
          siret: company.siret ?? null,
          categoryId: null,
          isCompany: true,
          structureReferenceReason: null,
          structureInstanceReferentId: null,
          structureCatalogStructureTypeIds: null,
          structureCreationDate: null,
          structureWorkforce: 50,
          structureCatalogActivityId: null,
          structureApeId: company.activiteprincipaleetablissement ?? null,
          structureTeachingActivityPc: 50,
          name: company.nomunitelegale ?? null,
          structureAcronym: null,
          customAddressStreetDetails: null,
          customAddressStreetDetailsInfos: null,
          customAddressStreetNumber: company.numerovoieetablissement ? company.numerovoieetablissement.toString() : null,
          customAddressStreetNumberIndex: company.typevoieetablissement ?? null,
          customAddressStreetTypeId: null,
          customAddressStreetLabel: company.libellevoieetablissement ?? null,
          customAddressCityPlace: null,
          commercialName: company.libellecommuneetablissement ?? null,
          zip: company.codepostaletablissement ?? null,
          city: company.libellecommuneetablissement ?? null,
          customAddressCityInsee: company.codecommuneetablissement ?? null,
          partnerLatitude: company.geolocetablissement ? company.geolocetablissement[0] : null,
          partnerLongitude: company.geolocetablissement ? company.geolocetablissement[1] : null,
          countryId: null,
          website: null,
          phone: null,
          mobile: null,
          email: null,
          structureActivityDescription: null,
          structureOfferTrainingPlace: null,
          structureOfferTrainingDeclarationNumber: null,
          structureOfferTrainingPlaceAccess: null,
          structureOfferAccomodation: null,
          structureOfferRestoration: null,
          structureOfferDisabilityReferentFirstname: null,
          structureOfferDisabilityReferentPhone: null,
          structureOfferDisabilityReferentEmail: null,
          structureOfferDisabilityReferentLastname: null
        });
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données OpenDataSoft:', error);
    }
  };

  const formIsValid = () => {

    let isValid = true;
    const newStructureErrors = {};
    const newAdressErrors = {};
    const newContactErrors = {};

    const required = "Le champ est obligatoire";
    const badAddress = "L'adresse sélectionnée comporte des erreurs";

    // vérification des champs de la structure
    if (newStructure.siret) {

      if (!newStructure["siret"] || newStructure["siret"] === null) {
        newStructureErrors["siret"] = required;
        isValid = false;
      }
      if (!newStructure["name"] || newStructure["name"] === null) {
        newStructureErrors["name"] = required;
        isValid = false;
      }
      if (!newStructure["commercialName"] || newStructure["commercialName"] === null) {
        newStructureErrors["commercialName"] = required;
        isValid = false;
      }
      if (!newStructure["email"] || newStructure["email"] === null) {
        newStructureErrors["email"] = required;
        isValid = false;
      }

      setStructureErrors(newStructureErrors);

    }

    if (
      Object.keys(addressesErrors).length > 0 ||
      newStructure.siret && newAddress ||
      Object.entries(newAddress)
        .filter(([key]) => key !== "type") // Exclure la clé "type"
        .some(([, value]) => value != null) // Vérifier si une valeur est différente de null
      || !selectedAddress

    ) {
      if (!newAddress["customAddressStreetNumber"] || newAddress["customAddressStreetNumber"] === null) {
        newAdressErrors["customAddressStreetNumber"] = badAddress;
        isValid = false;
      }
      if (!newAddress["customAddressStreetLabel"] || newAddress["customAddressStreetLabel"] === null) {
        newAdressErrors["customAddressStreetLabel"] = badAddress;
        isValid = false;
      }
      if (!newAddress["customAddressCityInsee"] || newAddress["customAddressCityInsee"] === null) {
        newAdressErrors["customAddressCityInsee"] = badAddress;
        isValid = false;
      }
      if (!newAddress["zip"] || newAddress["zip"] === null) {
        newAdressErrors["zip"] = badAddress;
        isValid = false;
      }

      setAddresesErrors(newAdressErrors);
      if (!newContact["name"] || newContact["name"] === null) {
        newContactErrors["name"] = required;
        isValid = false;
      }
      if (!newContact["email"] || newContact["email"] === null) {
        newContactErrors["email"] = required;
        isValid = false;
      }
      if (!newContact["mobile"] || newContact["mobile"] === null) {
        newContactErrors["mobile"] = required;
        isValid = false;
      }
      setContactErrors(newContactErrors)
    }
    return isValid;
  }


  // API pour récupérer la structure Odoo avec le SIRET
  const fetchOdooStructure = async (siret) => {
    try {
      setNewAddress({
        type: 3,
        contactAddressComplete: null,
        customAddressStreetNumber: null,
        customAddressStreetTypeId: null,
        customAddressStreetLabel: null,
        customAddressCityInsee: null,
        street: null,
        zip: null,
      })
      setNewContact({
        name: null,
        email: null,
        mobile: null,
      });
      setNewStructure({});
      setAddresesErrors({});
      setContactErrors({});
      setStructureErrors({});
      setSelectedAddress(null);
      const response = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/structure/${siret}`);
      const data = await response.json();
      if (data.status && data.structures && data.structures.length > 0) {
        setOdooStructure(data.status && data.structures && data.structures.length > 0 ? data.structures[0] : null);
        setStructureExists(data.status && data.structures && data.structures.length > 0);
      } else {
        setOdooStructure(null);
        setDeliveryAdresses(null);
        fetchOpenDataSoftStructureInfo(siret);
      }
    } catch (error) {
      console.error("Erreur lors de la recherche SIRET dans Odoo:", error);
      setOdooStructure(null);
    }
  };

  // API pour récupérer les types de rues depuis Odoo
  const fetchOdooStreetTypes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/street_type`);
      const data = await response.json();
      setOdooStreetTypes(data);
    } catch (error) {
      console.error("Impossible de récupérer le catalogue des types de rues", error);
      setOdooStreetTypes(null);
    }
  };

  const fetchOdooApeCatalog = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/ape`);
      const data = await response.json();
      setOdooApeCatalog(data);
    } catch (error) {
      console.error("Impossible de récupérer le catalogue des codes APE", error);
      setOdooApeCatalog(null);
    }
  }

  // API pour récupérer les adresses de livraison existantes pour le SIRET
  const fetchDeliveryAddresses = async (siret) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SOURCE}/api/v1/cpExpos/expos/addresses/${siret}`
      );
      const data = await response.json();
      setDeliveryAdresses(data);
    } catch (error) {
      console.error("Erreur lors de la recherche des adresses de livraison", error);
    }
  };

  // Méthode pour créer une nouvelle adresse
  const createAddress = async (structureId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1/cpExpos/expos/createContactAddress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...newAddress,
          commercialPartnerId: structureId,
          parentId: structureId,
          isCompany: false,
          ...newContact
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erreur lors de la création de l’adresse :', error);
    }
  };

  const createNewStructure = async (newStructure) => {
    const nameToFind = newStructure.customAddressStreetTypeName; // Assurez-vous que ce champ existe dans newStructure
    const matchingStreetType = odooStreetTypes.find(
      (type) => type.name === nameToFind
    );

    const apeCodeToFind = newStructure.structureApeId.replace('.', '');
    const matchingApeId = odooApeCatalog.find(
      (type) => type.code === apeCodeToFind
    );

    // Créez une copie de newStructure avec le champ mis à jour
    const updatedStructure = {
      ...newStructure,
      customAddressStreetTypeId: matchingStreetType ? matchingStreetType.id : null,
      structureApeId: matchingApeId ? matchingApeId.id : null
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1/cpExpos/expos/createLocationStructure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedStructure), // Envoie l'objet newStructure
      });
      const data = await response.json(); // Parse la réponse JSON
      return data;
    } catch (error) {
      console.error('Erreur lors de la création de la structure :', error);
      alert('Erreur lors de la création de la location.');
    }
  };

  // Méthode pour créer une nouvelle location
  const createLocation = async (e) => {

    e.preventDefault(); // Empêche le rechargement de la page

    var createdAdresseId = undefined
    var createdStructureId = undefined

    if (formIsValid()) {
      // dans le cas d'une nouvelle nouvelle structure
      if (newStructure.city) {
        // faire vérification formulaire adresse et contact
        createdStructureId = await createNewStructure(newStructure);
      }
      if (newAddress.zip && newContact.name) {
        // faire vérification formulaire adresse et contact

        // on crée en base la nouvelle adresse et on récupère son id
        createdAdresseId = await createAddress(createdStructureId ? createdStructureId : odooStructure.id);
      }

      // on crée la location

      try {
        const response = await fetch(`${process.env.REACT_APP_API_SOURCE}/api/v1/cpExpos/expos/createSale`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            partnerId: createdStructureId ? createdStructureId : odooStructure.id,
            partnerInvoiceId: createdStructureId ? createdStructureId : odooStructure.id,
            partnerShippingId: createdAdresseId ? createdAdresseId : selectedAddress.id,
            productId: product.id,
            startDate: formatToLocalISOString(reservedDates[0]),
            returnDate: formatToLocalISOString(reservedDates[1]),
            signatory: createdAdresseId ? newContact.name : selectedAddress.name,
            contactName: createdAdresseId ? newContact.name : selectedAddress.name,
            contactEmail: createdAdresseId ? newContact.email : selectedAddress.email,
            contactMobile: createdAdresseId ? newContact.mobile : selectedAddress.mobile,
            productName: product.name
          }),
        });
        if (response.ok) {
          onNext();
        } else {
          alert('Erreur lors de la création de la location.');
        }
      } catch (error) {
        console.error('Erreur lors de la création de la location :', error);
      }
    }
  };

  useEffect(() => {
    if ((Object.keys(structureErrors).length > 0 || Object.keys(addressesErrors).length > 0 || Object.keys(contactErrors).length > 0)) {
      formIsValid();
    }
  }, [newAddress, newContact, newStructure]);

  useEffect(() => {
    fetchOdooStreetTypes(); // Appel de la méthode pour récupérer les types de rues
    fetchOdooApeCatalog();
    if (siret.length) {
      // je commence la procédure
      fetchOdooStructure(siret);
    } else {
      setOdooStructure(null);
      setDeliveryAdresses(null);
    }
  }, [siret, structureExists]);

  useEffect(() => {
    if (odooStructure) {
      fetchDeliveryAddresses(siret);
    }
  }, [odooStructure]);


  return (
    <>
      <div className='cp-form-step-three'>
        <h3>Étape 3 : Choix structure et contact</h3>
        <Form onSubmit={createLocation}>
          <div className='structure-form-container-siret'>

            <label htmlFor="siret">SIRET de la structure :</label>
            <input
              type="text"
              id="siret"
              value={siret}
              onChange={(e) => setSiret(e.target.value)}
              placeholder="Entrez le SIRET (14 caractères minimum)"
            />
          </div>

          {odooStructure && (
            <>
              <OdooStructureCard
                odooStructure={odooStructure}
                setAdressSectionIsOpen={setAdressSectionIsOpen}
              />
              {deliveryAddresses && deliveryAddresses.length > 0 && (
                <div>
                  <p><b>Veuillez sélectionner une adresse d'installation liée à votre nom.</b></p>
                  <p>Si aucune adresse n'est disponible ou si l'adresse souhaitée n'existe pas, veuillez créer un nouveau lieu d'installation en remplissant le formulaire dédié</p>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {deliveryAddresses.map((address) => (
                      <OdooDeliveryAddress
                        key={address.id}
                        odooDeliveryAdress={address}
                        onSelect={() => {
                          setAddresesErrors({})
                          setContactErrors({})
                          setSelectedAddress(
                            selectedAddress?.id === address.id ? null : address
                          )
                        }
                        }
                        isSelected={selectedAddress?.id === address.id}
                      />

                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          {newStructure && newStructure.siret && (
            <>
              <p>Vous n'êtes pas référencé dans notre base de données:</p>
              <StructureSection
                structureInfo={newStructure}
                setStructureInfo={setNewStructure}
                odooStreetTypes={odooStreetTypes}
                errors={structureErrors}
              />
              <ContactSection
                newContact={newContact}
                setNewContact={setNewContact}
                errors={contactErrors}
              />
              <AddressSection
                streetTypeCatalog={odooStreetTypes}
                newAddress={newAddress}
                setNewAddress={setNewAddress}
                errors={addressesErrors}
              />
            </>
          )}

          {odooStructure && (
            <>
              <hr></hr>
              <p>Nouvelle adresse d'installation</p>
              <ContactSection
                newContact={newContact}
                setNewContact={setNewContact}
                errors={contactErrors}
              />
              <AddressSection
                streetTypeCatalog={odooStreetTypes}
                newAddress={newAddress}
                setNewAddress={setNewAddress}
                errors={addressesErrors}
              />

            </>
          )}

          <div className="error-message">
            {(Object.keys(structureErrors).length > 0 || Object.keys(addressesErrors).length > 0 || Object.keys(contactErrors).length > 0) && (
              <p style={{ color: 'red', marginTop: '1em' }}>
                Veuillez remplir tous les champs obligatoires *
              </p>
            )}
          </div>
          <button type="submit" className='btn-change-step'>Créer la location</button>
        </Form>
      </div>

    </>
  );
};

export default CpExposFormStepThree;
