import { MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const AddressSection = ({ streetTypeCatalog, newAddress, setNewAddress, errors }) => {
  const setAddressData = (address) => {
    const [nameToFind, ...streetLabelParts] = address.properties.street.split(" ");
    const matchingStreetType = streetTypeCatalog.find(
      (type) => type.name === nameToFind
    );

    setNewAddress({
      ...newAddress,
      type: 3,
      contactAddressComplete: null,
      customAddressStreetNumber: address.properties.housenumber,
      customAddressStreetTypeId: matchingStreetType?.id || null,
      customAddressStreetLabel: matchingStreetType? streetLabelParts.join(" ") : address.properties.street,
      customAddressCityInsee: address.properties.citycode,
      zip: address.properties.postcode,
      city: address.properties.city,
    });
  };

  const [addressesList, setAddressesList] = useState([]);


  const handleChangeAddress = async (inputValue) => {
    const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${inputValue}`);
    if (response.data.features) {
      setAddressesList(response.data.features);
    }
  }

  return (
    <div className='step-three-form-open-section'>
      <div className='step-three-form-section'>
        <div>
          {/* Section Adresse */}
          <div className="address-section">
            <Form.Label style={{ color: Object.keys(errors).length > 0 ? 'red' : '' }}>Adresse*</Form.Label>
            {Object.keys(errors).length>0 && <p style={{ textAlign: 'left', color:'red' }}>L'adresse sélectionnée n'est pas valide.</p>}
            <AsyncTypeahead
              filterBy={() => true}
              labelKey={(addressesList) => `${addressesList.properties.name} ${addressesList.properties.city.toUpperCase()} (${addressesList.properties.postcode})`}
              minLength={3}
              onSearch={handleChangeAddress}
              options={addressesList}
              emptyLabel=""

              promptText="Tapez pour chercher..."
              placeholder="Rechercher une adresse"
              onChange={(selected) => {
                if (selected && selected.length > 0) {
                  const newValue = selected[0];
                  setAddressData(newValue);
                } else {
                  setAddressesList([]);
                }
              }}
              inputProps={{ style: { height: '3em' } }}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default AddressSection;
