import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/CpExposFormStepOne.css';
import Markdown from 'markdown-to-jsx';
import { Spinner } from 'react-bootstrap';
import { CircularProgress, Fade, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CpExposFormStepOne = ({ onNext, onPrevious }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCoupDeProjecteur, setShowCoupDeProjecteur] = useState(false); // État pour dérouler/fermer Coup de Projecteur
  const [showGrandesExpos, setShowGrandesExpos] = useState(false); // État pour dérouler/fermer Grandes Expos

  // Récupérer les produits via l'API lors du montage du composant
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1/cpExpos/expos/expoProducts`)
      .then(response => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des produits:', error);
      });
  }, []);

  // Fonction pour sélectionner un produit et afficher les détails
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleNextClick = () => {
    if (selectedProduct) {
      onNext(selectedProduct); // Passe à l'étape 2 avec le produit sélectionné
    }
  };

  // Séparer les produits en deux catégories
  const coupDeProjecteurProducts = products.filter(product => product.productTagIds[0] === 1);
  const grandesExposProducts = products.filter(product => product.productTagIds[0] === 2);

  return (
    loading && (
      <div className='loader-container'>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress color="secondary" />
        </Fade>
      </div>
    )
    ||
    <div className="cp-expos-form-step-one">
      {/* Sidebar */}
      <div className="sidebar">
        <IconButton
          onClick={onPrevious}
          color="secondary"
          size="small"
        >
          <ArrowBackIcon />
        </IconButton>
        <h2>Nos produits</h2>

        {/* Coup de projecteur Section */}
        <div className="product-category">
          <h3 onClick={() => setShowCoupDeProjecteur(!showCoupDeProjecteur)} className="category-title">
            Coups de projecteurs {showCoupDeProjecteur ? '-' : '+'}
          </h3>
          {showCoupDeProjecteur && (
            <div className="product-list">
              {coupDeProjecteurProducts.map(product => (
                <div
                  key={product.id}
                  className={`product-card ${selectedProduct && selectedProduct.id === product.id ? 'active' : ''}`}
                  onClick={() => handleProductClick(product)}
                >
                  <h3>{product.name}</h3>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Grandes Expos Section */}
        <div className="product-category">
          <h3 onClick={() => setShowGrandesExpos(!showGrandesExpos)} className="category-title">
            Grandes Expos {showGrandesExpos ? '-' : '+'}
          </h3>
          {showGrandesExpos && (
            <div className="product-list">
              {grandesExposProducts.map(product => (
                <div
                  key={product.id}
                  className={`product-card ${selectedProduct && selectedProduct.id === product.id ? 'active' : ''}`}
                  onClick={() => handleProductClick(product)}
                >
                  <h3>{product.name}</h3>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {selectedProduct ? (
          <>
            <div className='product-container'>
              {
                selectedProduct.productTagIds[0] === 1 &&
                (
                  <div>
                    <h3>Coup de projecteur</h3>
                    <ul>
                      <li>Disposer d’une salle de 30 m2 pour un Coup de projecteur</li>
                      <li>La salle doit être accessible, sécurisée, équipée de prises électriques et d’une connexion internet.</li>
                      <li>Identifier des professionnels pour animer l’exposition.</li>
                      <li>Garantir la présence d’un interlocuteur technique, sur le site lors du montage et du démontage de l’exposition</li>
                      <li>Réserver le Coup de Projecteur 5 jours ouvrés minimum (hors montage et démontage) et 2 mois pour une Grande Exposition</li>
                      <li>Une planification des animations</li>
                    </ul>
                  </div>
                  ) || selectedProduct.productTagIds[0] === 2 &&
                  (
                    <div>
                      <h3>Grande Expo</h3>
                      <ul>
                        <li>Disposer d’une salle de 150 m2 </li>
                        <li>La salle doit être accessible, sécurisée, équipée de prises électriques et d’une connexion internet.</li>
                        <li>Identifier des professionnels pour animer l’exposition.</li>
                        <li>Garantir la présence d’un interlocuteur technique, sur le site lors du montage et du démontage de l’exposition</li>
                        <li>Réserver la grande exposition 2 mois </li>
                        <li>Une planification des animations</li>
                      </ul>
                    </div>
                )
              }
            </div>
            <div className="product-container">
              <h2>{selectedProduct.name}</h2>
              <div className='product-details'>
                {selectedProduct.image512 && (
                  <img src={`data:image/jpeg;base64,${selectedProduct.image512}`} alt={selectedProduct.name} className="product-detail-image" />
                )}
                  <div className="description-columns">
                    <Markdown>{selectedProduct.descriptionSale}</Markdown>
                  </div>
              </div>
              <button className="btn-change-step" onClick={handleNextClick}>Réserver ce produit</button>
            </div>
          </>
        ) : (
          <p>Sélectionnez un produit pour voir les détails.</p>
        )}
      </div>
    </div>
  );
};

export default CpExposFormStepOne;
