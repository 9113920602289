import {BrowserRouter, Route, Routes} from "react-router-dom";
import Adhesion from "./pages/adhesion/adhesion";
import 'bootstrap/dist/css/bootstrap.min.css';
import HorizontalLinearStepper from "./pages/adhesion/stepper";
import Memori from "./pages/memori/memori";
import  NavBar from "./components/nav-bar";
import CpExposFormStepOne from "./pages/CpExpos/CpExposFormStepOne";
import CpExposForm from "./pages/CpExpos/CpExposForm";

function Home() {
  return (
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <NavBar />
                  </div>
              </div>
          </div>
  );
}


function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/adhesion" element={<Adhesion />} />
          <Route path="/referencement/:siret?" element={<HorizontalLinearStepper />} />
          <Route path="/memori" element={<Memori />} />
          <Route path="/cp-expos" element={<CpExposForm />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
