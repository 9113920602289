import React, { useState } from 'react';
import CpExposFormStepOne from './CpExposFormStepOne';
import CpFormStepTwo from './CpFormStepTwo';
import '../../styles/CpExposForm.css'
import CpExposFormStepThree from './CpExposFormStepThree';
import CpExposFinalStep from './CpExposFormFinalStep';
import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const CpExposForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [reservedDates, setReservedDates] = useState(null)

  const handleReserveClick = () => {
    setCurrentStep(1);
  };

  const goToStepTwo = (product) => {
    setSelectedProduct(product);
    setCurrentStep(2);
  };

  const goToStepThree = (receivedReservedDates) => {
    setCurrentStep(3);
    setReservedDates(receivedReservedDates)
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  }

  return (
    <div className="cp-expos-form">
      {/* Afficher le header uniquement si currentStep n'est ni 1 ni 4 */}
      {currentStep && currentStep !== 1 && currentStep !== 4 ? (
        <header>
          <IconButton
            style={{ marginLeft: '10em' }}
            onClick={() => setCurrentStep(currentStep - 1)}
            color="secondary"
            size="small"
          >
            <ArrowBack />
          </IconButton>
          <div className="logo-titre mt-2">
            <img src="/logo-cap-metiers.png" alt="Logo Cap Métiers Nouvelle-Aquitaine" />
          </div>
        </header>
      ) : null}



      {/* Page d'accueil */}
      {currentStep === 0 && (
        <div className="project-presentation">
          <h1>Grandes expositions et coups de projecteur</h1>
          <div className="body-presentation">
            <h2>Un CMTT dans votre territoire</h2>
            <p>
              Un Cap Métiers Tour Thématique (CMTT) permet à une structure de <b>réserver une de nos expositions</b> (Coup de
              Projecteur ou Grande Exposition) pour lui permettre d’enrichir et compléter son action de découverte des métiers
              en direction de son public.
            </p>
            <p>
              Cette exposition peut s’accompagner de nos autres outils de découverte des métiers, comme des vidéos en 360°,
              des serious games, des quizz, ainsi que de notre nouveau jeu de plateau « Borealis ».
            </p>
            <h2>Pour qui ?</h2>
            <p>
              Toute structure de la Nouvelle-Aquitaine travaillant dans le secteur de l’orientation, de l’insertion, de la formation ou
              de l’emploi et se situant à plus d’une heure de transport en commun d’un site de Cap Métiers (Pessac, La Rochelle, Limoges) peut réserver une exposition.
            </p>
            <p>
              Un Cap Métiers Tour Thématique peut s’inscrire dans une manifestation existante ou dans un projet spécifique.
            </p>
          </div>
          <button className="btn-change-step" onClick={handleReserveClick}>
            Réserver un produit
          </button>
        </div>
      )}

      {/* Étape 1 */}
      {currentStep === 1 && (
        <CpExposFormStepOne onNext={goToStepTwo} onPrevious={goToPreviousStep} />
      )}

      {/* Étape 2 */}
      {currentStep === 2 && selectedProduct && (
        <CpFormStepTwo product={selectedProduct} onNext={goToStepThree} onPrevious={goToPreviousStep} />
      )}

      {/* Étape 3 */}
      {currentStep === 3 && (
        <CpExposFormStepThree
          product={selectedProduct}
          reservedDates={reservedDates}
          onNext={() => setCurrentStep(4)}
          onPrevious={goToPreviousStep}
        />
      )}

      {/* Étape 4 */}
      {currentStep === 4 && <CpExposFinalStep />}
    </div>
  );

};

export default CpExposForm;
